var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"course-main-wrap"},[_c('div',{staticClass:"course-main-header flex mb24"},[_c('div',{staticClass:"header-desc f18"},[_vm._v(_vm._s(_vm.courseTitle))]),_c('div',{staticClass:"header-bread flex y-center"},[(!_vm.isMobile)?_c('div',{staticClass:"header-bread-text f14 mr8"},[_vm._v("当前位置：")]):_vm._e(),_c('wj-breadcrumb',{attrs:{"separator-class":"wj-icon-arrow-right"}},[_c('wj-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),(!_vm.educateeId)?_c('wj-breadcrumb-item',{attrs:{"to":{
                            path: '/admin/course',
                        }}},[_vm._v("课程管理")]):_vm._e(),(_vm.schoolName)?_c('wj-breadcrumb-item',{attrs:{"to":{
                            path: '/course/list',
                            query: { id: _vm.educateeId, name: _vm.schoolName },
                        }}},[_vm._v(_vm._s(_vm.schoolName))]):_vm._e(),(_vm.educateeId)?_c('wj-breadcrumb-item',{attrs:{"to":{
                            path: '/course/catalog',
                            query: { cid: _vm.courseId, id: _vm.educateeId },
                        }}},[_vm._v(_vm._s(_vm.courseName))]):_c('wj-breadcrumb-item',{attrs:{"to":{
                            path: '/admin/catalog',
                            query: { cid: _vm.courseId },
                        }}},[_vm._v(_vm._s(_vm.courseName))]),_c('wj-breadcrumb-item',[_vm._v(_vm._s(_vm.courseTitle))])],1)],1)]),_c('div',{staticClass:"course-main-content"},[_vm._m(0),(_vm.isMobile)?_c('div',{staticClass:"brief"},[_vm._v(_vm._s(_vm.introduction))]):_vm._e(),_c('div',{staticClass:"course-catalog"},[_c('div',{staticClass:"title",on:{"click":_vm.handleClickTitle}},[_vm._v(" 课程目录 "),(_vm.isMobile && _vm.showCatalogue)?_c('i',{staticClass:"iconfont icon-shouqi"}):_vm._e(),(_vm.isMobile && !_vm.showCatalogue)?_c('i',{staticClass:"iconfont icon-xiala"}):_vm._e()]),(_vm.showCatalogList)?_c('div',{staticClass:"catalog-list"},_vm._l((_vm.courseList),function(item){return _c('div',{key:item.episodeId,staticClass:"list-item",class:{ active: item.episodeId == _vm.episodeId },on:{"click":function($event){return _vm.handleSelectVideo(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0):_vm._e()]),(!_vm.isMobile)?_c('div',{staticClass:"brief"},[_vm._v(_vm._s(_vm.introduction))]):_vm._e()]),_c('div',{staticClass:"btn-options"},[_c('wj-button',{attrs:{"type":"primary"},on:{"click":_vm.gotoExamUrl}},[_vm._v("点击考试")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-video"},[_c('div',{attrs:{"id":"J_prismPlayer"}})])
}]

export { render, staticRenderFns }