<template>
    <div class="main-container">
        <div class="course-main-wrap">
            <div class="course-main-header flex mb24">
                <div class="header-desc f18">{{ courseTitle }}</div>
                <div class="header-bread flex y-center">
                    <div v-if="!isMobile" class="header-bread-text f14 mr8">当前位置：</div>
                    <wj-breadcrumb separator-class="wj-icon-arrow-right">
                        <!-- <wj-breadcrumb-item :to="{ path: '/' }"
                                        >首页</wj-breadcrumb-item
                                    > -->
                        <wj-breadcrumb-item :to="{ path: '/' }">首页</wj-breadcrumb-item>
                        <wj-breadcrumb-item
                            v-if="!educateeId"
                            :to="{
                                path: '/admin/course',
                            }"
                            >课程管理</wj-breadcrumb-item
                        >
                        <wj-breadcrumb-item
                            v-if="schoolName"
                            :to="{
                                path: '/course/list',
                                query: { id: educateeId, name: schoolName },
                            }"
                            >{{ schoolName }}</wj-breadcrumb-item
                        >
                        <wj-breadcrumb-item
                            v-if="educateeId"
                            :to="{
                                path: '/course/catalog',
                                query: { cid: courseId, id: educateeId },
                            }"
                            >{{ courseName }}</wj-breadcrumb-item
                        >
                        <wj-breadcrumb-item
                            v-else
                            :to="{
                                path: '/admin/catalog',
                                query: { cid: courseId },
                            }"
                            >{{ courseName }}</wj-breadcrumb-item
                        >
                        <wj-breadcrumb-item>{{ courseTitle }}</wj-breadcrumb-item>
                    </wj-breadcrumb>
                </div>
            </div>
            <div class="course-main-content">
                <div class="course-video">
                    <div id="J_prismPlayer"></div>
                </div>
                <div v-if="isMobile" class="brief">{{ introduction }}</div>
                <div class="course-catalog">
                    <div class="title" @click="handleClickTitle">
                        课程目录
                        <i v-if="isMobile && showCatalogue" class="iconfont icon-shouqi"></i>
                        <i v-if="isMobile && !showCatalogue" class="iconfont icon-xiala"></i>
                    </div>
                    <div class="catalog-list" v-if="showCatalogList">
                        <div
                            class="list-item"
                            v-for="item in courseList"
                            :key="item.episodeId"
                            :class="{ active: item.episodeId == episodeId }"
                            @click="handleSelectVideo(item)"
                        >
                            {{ item.title }}
                        </div>
                    </div>
                </div>
                <div v-if="!isMobile" class="brief">{{ introduction }}</div>
            </div>
            <div class="btn-options">
                <wj-button type="primary" @click="gotoExamUrl">点击考试</wj-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Breadcrumb, BreadcrumbItem, Button } from '@wenjuan/ui';
import {
    httpGetCourseEpisodeDetail,
    httpGetPlayAuth,
    httpGetCourseEpisodeList,
    httpPostExam,
    httpGetCourseInfo,
} from '@/http/user/index.js';
export default {
    name: 'player',
    data() {
        return {
            playAuth: null, //播放凭证
            courseId: null,
            episodeId: null, //课程集id
            courseList: [],
            player: null,
            examUrl: '',
            schoolName: '',
            courseName: '',
            courseTitle: '',
            educateeId: '',
            introduction: '',
            isMobile: false,
            showCatalogue: false, // 移动端展示目录
        };
    },
    created() {
        if (
            navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
            this.isMobile = true;
        }
        this.courseId = this.$route.query.cid;
        this.episodeId = this.$route.query.eid;
        this.educateeId = this.$route.query.id;
        this.getCourseList();
        this.getCourseInfo(this.educateeId, this.courseId);
        // this.getExamUrl;
    },
    watch: {
        // episodeId(val) {
        //     this.getExamUrl(val);
        // },
    },
    computed: {
        // 展示目录列表
        showCatalogList() {
            if (!this.isMobile) {
                return true;
            } else {
                return this.showCatalogue;
            }
        },
    },
    methods: {
        //获取课程详情
        getCourseInfo(educateeId, courseId) {
            httpGetCourseInfo({ educateeId, courseId }).then((res) => {
                let { school, title } = res.data.data;
                this.schoolName = school;
                this.courseName = title;
            });
        },
        getCourseList() {
            httpGetCourseEpisodeList(this.courseId).then((res) => {
                this.courseList = res.data.data;
                console.log(123, this.courseList);
                if (!this.episodeId) {
                    this.episodeId = this.courseList[0].episodeId;
                }
                this.getCourseDetail();
            });
        },
        //获取课程集详情
        async getCourseDetail() {
            let episode = await httpGetCourseEpisodeDetail(this.episodeId);
            let videoId = episode.data.data.videoId;
            this.courseTitle = episode.data.data.title;
            this.introduction =
                episode.data.data.introduction ||
                '';
            httpGetPlayAuth(videoId).then((res) => {
                this.playAuth = res.data.data.playAuth;
                let player = new Aliplayer(
                    {
                        id: 'J_prismPlayer',
                        width: '100%',
                        height: '100%',
                        vid: videoId,
                        playauth: this.playAuth,
                        useH5Prism: this.isMobile,
                    },
                    function (player) {
                        console.log('The player is created.', player);
                    }
                );
                this.player = player;
            });
        },
        handleSelectVideo(item) {
            this.episodeId = item.episodeId;
            let videoId = item.videoId;
            this.courseTitle = item.title;
            httpGetPlayAuth(videoId).then((res) => {
                this.playAuth = res.data.data.playAuth;
                this.player.replayByVidAndPlayAuth(item.videoId, this.playAuth);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });

            // httpGetPlayAuth(item.videoId);
        },
        //获取当前用户的考试
        gotoExamUrl() {
            httpPostExam(this.episodeId, { educateeId: this.educateeId })
                .then((res) => {
                    let url = res.data.data.url;
                    window.open(url);
                })
                .catch(() => {});
        },
        // 移动端-目录收缩
        handleClickTitle() {
            if (!this.isMobile) {
                return;
            }
            this.showCatalogue = !this.showCatalogue;
        },
    },
    components: {
        'wj-breadcrumb': Breadcrumb,
        'wj-breadcrumb-item': BreadcrumbItem,
        'wj-button': Button,
    },
};
</script>
<style scoped lang="scss">
.main-container {
    background-color: #25282a;
    .course-main-wrap {
        width: 1330px;
        margin: 0 auto;
        @media (max-width: 640px) {
            width: 100%;
            box-sizing: border-box;
            padding: 0 24px;
        }
        .course-main-header {
            padding: 30px 0 5px 0;
            flex-direction: column;
            @media (max-width: 640px) {
                padding: 36px 0 0;
                margin-bottom: 30px;
            }
            .header-desc {
                font-size: 32px;
                color: #fff;
                margin-right: 40px;
                @media (max-width: 640px) {
                    font-family: PingFangSC-Medium;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: bold;
                    margin-bottom: 8px;
                    margin-right: 0;
                }
            }
            .header-bread {
                color: #8f8f8f;
                font-size: 14px;
                margin-top: 17px;
                @media (max-width: 640px) {
                    margin-top: 0;
                    font-size: 12px;
                }
                ::v-deep .wj-breadcrumb__inner {
                    color: #8f8f8f;
                    line-height: 17px;
                }
            }
        }
        .course-main-content {
            box-sizing: border-box;
            width: 100%;
            height: 600px;
            display: flex;
            position: relative;
            @media (max-width: 640px) {
                height: auto;
                flex-direction: column;
            }
            .course-video {
                width: 1063px;
                height: 100%;
                @media (max-width: 640px) {
                    width: 100%;
                    height: 184px;
                }
            }
            .course-catalog {
                width: 267px;
                height: 100%;
                background: #2e3033;
                @media (max-width: 640px) {
                    width: 100%;
                    border-radius: 4px;
                }
                .title {
                    font-size: 16px;
                    color: #fff;
                    padding-left: 20px;
                    height: 70px;
                    line-height: 55px;
                    @media (max-width: 640px) {
                        color: rgba(255, 255, 255, 0.8);
                        height: 55px;
                        padding-right: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                .catalog-list {
                    height: calc(100% - 70px);
                    overflow-y: auto;
                    @media (max-width: 640px) {
                        height: auto;
                    }
                    .list-item {
                        height: 60px;
                        width: 100%;
                        color: #8f8f8f;
                        font-size: 14px;
                        padding-left: 20px;
                        cursor: pointer;
                        line-height: 60px;
                        box-sizing: border-box;
                        @media (max-width: 640px) {
                            padding-right: 20px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        &:hover {
                            background-color: #333539;
                        }
                        &.active {
                            color: #3385ff;
                            @media (max-width: 640px) {
                                color: #1a78ff;
                            }
                        }
                    }
                }
            }
            .brief {
                position: absolute;
                left: 0;
                bottom: -30px;
                color: #8f8f8f;
                font-size: 14px;
                @media (max-width: 640px) {
                    position: relative;
                    bottom: 0;
                    font-size: 12px;
                    margin: 10px 0;
                }
            }
        }
        .btn-options {
            width: 100%;
            text-align: center;
            padding: 70px 0 80px 0;
            @media (max-width: 640px) {
                padding: 30px 0 60px;
            }
            .wj-button {
                width: 180px;
                height: 50px;
            }
        }
    }
    ::v-deep .prism-big-play-btn {
        @media (max-width: 640px) {
            left: calc(50% - 16px) !important;
            bottom: calc(50% - 16px) !important;
            width: 32px;
            height: 32px;
            .outter {
                width: 32px;
                height: 32px;
            }
        }
    }
}
</style>
